import React from 'react';

/**
 * The footer.
 * @return {jsx} The component.
 */
function Footer() {
  return (
    <div className='flex flex-col sm:flex-row justify-evenly bg-zinc-800 text-white md:space-y-0 space-y-3 p-5 mt-10 md:p-10'>
      <div className='text-center'>
        <p className='font-bold'>Boutique Hors Taxe Lacolle</p>
        <p>303 Route 221 <br />Lacolle, QC</p>
        <p>450-246-2339</p>
      </div>
      <div className='text-center'>
        <p className='font-bold'>Boutique Hors Taxe Richelieu</p>
        <p>148 Route 223 <br />Lacolle, QC</p>
        <p>450-246-2227</p>
      </div>
    </div>
  );
}

export default Footer;
