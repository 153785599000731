import React from 'react';
import canadaTous from '../assets/img/exemption/1.png';
import ustoCanada from '../assets/img/exemption/2.png';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * Page for the exemptions.
 * @return {jsx} The component.
 */
function Exemption() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <PageTitle title={'Exemption'} subtext={t('exemptionMessage')} />
      <div className='border rounded-lg overflow-hidden m-2 md:m-5 p-10 bg-white'>
        <img src={canadaTous} alt="Canada vers les USA" className='img-fluid' />
        <img src={ustoCanada} alt="USA vers le Canada" className='img-fluid' />
      </div>
    </div>
  );
}

export default Exemption;
