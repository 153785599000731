import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * Contact page.
 * @return {jsx} The component.
 */
function Contact() {
  const { t } = useTranslation();
  const [sent, setSent] = React.useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    console.log('test trying to send', form.current);

    emailjs.sendForm('service_ghmhj7l', 'template_maunc4y', form.current, 'MRn6neoRDWOQ1kiXp')
      .then((result) => {
        console.log(result.text);
        setSent(true);
      }, (error) => {
        console.log(error.text);
      });
  };

  if (sent) {
    return (
      <div className='w-full lg:w-3/4 mx-auto rounded-lg px-4'>
        <PageTitle title={t('contact')} subtext={t('contactMessage')} />
        <div className='w-[3/4] m-2 mt-5 p-5 bg-white rounded border text-center'>
          {t('contactSent')}
        </div>
      </div>

    );
  }

  return (
    <div className='w-full lg:w-3/4 mx-auto rounded-lg'>
      <PageTitle title={t('contact')} subtext={t('contactMessage')} />
      <div className='text-left w-[3/4] m-2 mt-5 p-5 bg-white rounded border'>
        <form ref={form} onSubmit={sendEmail}>
          <div className="flex flex-col">
            <label htmlFor="user_name" className='py-2'>{t('nom')} *</label>
            <input type="text" className="form-control border p-3" name="user_name" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="user_email" className='py-2'>{t('email')} *</label>
            <input type="email" className="form-control border p-3" name="user_email" />
          </div>
          <div className="flex flex-col">
            <label htmlFor="message" className='py-2'>Message *</label>
            <textarea className="form-control border p-3" rows="5" name="message" ></textarea>
          </div>
          <button type="submit" className="bg-black text-white p-5 rounded-lg my-4" value="Send">{t('envoyer')}</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
