import horaire221 from '../mocks/horaire221';
import horaire223 from '../mocks/horaire223';

async function getHoursForId(placeId) {
    if (placeId === 221) {
        return horaire221;
    } else {
        return horaire223;
    }
}

const HoursServices = { getHoursForId };

export default HoursServices;
