import React from 'react';

/**
 * Simple page title component
 * @param {*} title and subtext
 * @return {JSX} the component
 */
function PageTitle({ title, subtext }) {
    return (
        <div className='flex flex-col m-10 text-center mx-auto space-y-2'>
            <p className='font-bold text-4xl'>{title}</p>
            <p>{subtext}</p>
        </div>
    );
}

export default PageTitle;
