import React from 'react';
import Produit from '../components/Produit';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * The products page.
 * @return {jsx} The products page.
 */
function Produits() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <PageTitle title={t('populaire')} subtext={t('populaireMessage')} />
      <div className='flex flex-wrap justify-center'>
        <Produit code="11584013" nom="Crown Royale" prix="23.95" description="1140ml" prixSAQ="41.25" />
        <Produit code="11530841" nom="Bombay Sapphire" prix="20.95" description="1000ml" prixSAQ="43.25" />
        <Produit code="11724979" nom="Coureur des bois" prix="26.95" description="750ml" prixSAQ="36.75" />
        <Produit code="10915108" nom="Folonari Pinot Grigio" prix="12.95" description="750ml" prixSAQ="15.10" />
        <Produit code="10666826" nom="Bailey's" prix="34.95" description="1140ml" prixSAQ="42.75" />
        <Produit code="183251" nom="Absolut" prix="31.95" description="1750ml" prixSAQ="60.50" />
        <Produit code="1146257" nom="Appleton" prix="19.95" description="1140ml" prixSAQ="38.25" />
        <Produit code="13478679" nom="Bacardi Blanc" prix="16.95" description="1140ml" prixSAQ="34.50" />
        <Produit code="11495601" nom="Deward's White Label" prix="19.95" description="1140ml" prixSAQ="39.25" />
      </div>
    </div>
  );
}

export default Produits;
