import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';

/**
 * Component for the 404 page.
 * @return {JSX} The component.
 */
function Erreur404() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto my-10'>
      <PageTitle title={t('oups')} subtext={t('oupsMessage')} />
    </div>
  );
}

export default Erreur404;
