import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Produits from './pages/Produits';
import Erreur404 from './pages/Erreur404';
import Contact from './pages/Contact';
import Scotch from './pages/Scotch';
import Exemption from './pages/Exemption';
import Heures from './pages/Heures';
import './index.css';
import './i18n';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

/**
 * App.
 * @return {jsx} The app.
 */
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="produits" element={<Produits />} />
            <Route path="scotch" element={<Scotch />} />
            <Route path="exemption" element={<Exemption />} />
            <Route path="heures-ouverture" element={<Heures />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<Erreur404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
