import React from 'react';
import image1 from '../assets/img/banner/1.jpeg';
import image2 from '../assets/img/banner/2.jpeg';
import image3 from '../assets/img/banner/3.jpeg';
import image4 from '../assets/img/banner/4.jpeg';

import { useLocation } from 'react-router-dom';

/**
 * Banner componant.
 * @return {jsx} The component.
 */
function Banner() {
  const location = useLocation();
  const currentUrl = location.pathname;

  let imageURL = '';

  // Really not the best way to do this, but it works for now.
  if (currentUrl === '/produits') {
    imageURL = image1;
  } else if (currentUrl === '/scotch') {
    imageURL = image2;
  } else if (currentUrl === '/exemption') {
    imageURL = image3;
  } else if (currentUrl === '/heures-ouverture') {
    imageURL = image4;
  } else {
    imageURL = image1;
  }

  return (
    <div style={{ height: '450px', backgroundImage: `url(${imageURL})`, backgroundPosition: 'center', backgroundSize: 'cover' }} />
  );
}

export default Banner;
