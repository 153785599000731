import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';
import HoursBlock from '../components/HoursBlock';

/**
 * Page for the hours.
 * @return {jsx} The component.
 */
function Heures() {
  const { t } = useTranslation();

  return (
    <div className='w-full lg:w-3/4 mx-auto px-4'>
      <PageTitle title={t('hours')} subtext={t('hoursMessage')} />
      <div className='flex flex-col lg:flex-row justify-center'>
        <HoursBlock name={'Lacolle'} dutyFreeId={221} />
        <HoursBlock name={'Richelieu'} dutyFreeId={223} />
      </div>
    </div>
  );
}

export default Heures;
